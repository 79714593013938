<!--
 * @Descripttion: 注册
 * @Author: zyr
 * @Date: 2021-05-28 23:35:38
 * @LastEditors: zyr
 * @LastEditTime: 2021-09-16 15:57:52
-->
<template>
  <div class="wrapper register-wrapper">
    <van-form @submit="onSubmit" @failed="onFail" :show-error-message="false" validate-first>
      <div class="form-item hack-border-bottom">
        <div class="flex-grow">
          <van-field v-model="username" placeholder="请输入姓名" name="username" autocomplete="off" maxlength="10"/>
        </div>
      </div>
       <div class="form-item hack-border-bottom">
        <div class="flex-grow">
          <van-field v-model="card" placeholder="请输入身份证号码"
            name="identityCard" autocomplete="off" maxlength="18" :error="false"
            :rules="[{
              message: '身份证号码不合法',
              pattern: /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
            }]"
          />
        </div>
      </div>
      <div class="form-item hack-border-bottom">
        <div class="flex-grow">
          <van-field v-model="phone" placeholder="请输入手机号码" name="phone" type="digit"
             maxlength="11" autocomplete="off" :error="false"
            :rules="[{
              message: '手机号码格式不正确',
              pattern: /^1[3-9]\d{9}$/
            }]"
          />
        </div>
      </div>
      <div class="form-item hack-border-bottom">
        <div class="flex-grow code">
          <van-field v-model="code" placeholder="请输入短信验证码" name="code" type="digit"  autocomplete="off" maxlength="6"/>
        </div>
        <span class="send-code" @click="getCode">{{timer ? time + 's' : '发送验证码'}}</span>
      </div>
      <div class="form-item hack-border-bottom">
        <div class="flex-grow">
          <van-field v-model="password" placeholder="设置登录密码" name="pwd"
            :type="showPass ? 'text' : 'password'" maxlength="16" :error="false"
            :rules="[{
              message: '密码格式不正确',
              pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
            }]"
          />
        </div>
        <img :src="showPass ? imgs[1] : imgs[0]" alt="" class="show-pass" @click="changeShowpass"/>
      </div>
      <p class="forget-password rule">6-16位密码，同时包含字母和数字，不区分大小写</p>
      <van-button :disabled="isDisable" :color="'#5460FE'" block native-type="submit"
        :style="isDisable ? {background: '#8F92BB', 'border-color': '#8F92BB'} : canClick ? '' : {background: '#e7e7e7', 'border-color': '#e7e7e7'}">{{canClick ? '确定' : '认证中...'}}</van-button>
    </van-form>
    <div class="agree-wrapper">
      <p :class="{'agree-crl': true, 'bg-blue': isAgree}" @click="changeAgree">
        <van-icon name="success" color="#fff" size="14px" v-show="isAgree"/>
      </p>
      <p class="agree-txt">我已成功并阅读<a class="color-blue" href="javascript:void(0)" @click="goPrivacy">《用户服务协议》</a></p>
    </div>
  </div>
</template>
<script>
import API from '@/assets/js/api'
const { register, login, getCode, auth, authRes } = API
export default {
  name: 'Register',
  data () {
    return {
      username: '',
      card: '',
      phone: '',
      code: '',
      password: '',
      showPass: false,
      isAgree: false,
      imgs: [
        require('@_img/passShow_no.png'),
        require('@_img/passShow.png')
      ],
      total: 59,
      time: 59,
      timer: null,
      canClick: true,
      canSend: true
    }
  },
  computed: {
    isDisable: function () {
      const arr = [this.username, this.card, this.phone, this.code, this.password]
      if (!arr.includes('') && this.isAgree) return false
      else return true
    }
  },
  methods: {
    goPrivacy () {
      localStorage.setItem('registerInfo', JSON.stringify({
        username: this.username,
        identityCard: this.card,
        phone: this.phone,
        code: this.code,
        pwd: this.password,
        showPass: this.showPass,
        isAgree: this.isAgree
      }))
      location.href = './privacy.html'
    },
    onFail (info) {
      const { errors: [{ message }] } = info
      this.$toast(message)
    },
    onSubmit (values) {
      if (!this.canClick) return
      this.canClick = false
      this.auth(values)
    },
    auth (values) {
      this.$axios.get(auth, {
        params: {
          username: values.username,
          identityCard: values.identityCard,
          phone: values.phone,
          code: values.code
        }
      }).then(({ code, msg, model }) => {
        if (code === 0) {
          localStorage.setItem('registerInfo', JSON.stringify({ ...values, showPass: this.showPass, isAgree: this.isAgree }))
          location.href = model
        } else {
          this.canClick = true
          this.$toast(msg)
        }
      })
    },
    getAuthRes (bizToken) {
      this.$axios.post(`${authRes}?bizToken=${bizToken}`, {}).then(({ code, msg }) => {
        if (code === 0) this.register()
        else {
          this.canClick = true
          this.$toast(msg)
        }
      })
    },
    register () {
      this.$axios({
        url: register,
        method: 'post',
        data: {
          username: this.username,
          identityCard: this.card,
          phone: this.phone,
          code: this.code,
          pwd: this.password
        },
        timeout: 60000
      }).then(({ code, msg }) => {
        this.canClick = true
        if (code === 0) this.login({ password: this.password, phone: this.phone })
        else this.$toast(msg)
      })
    },
    login (values) {
      this.$axios.post(login, values).then(({ code, msg, model: { token, ability } }) => {
        if (code === 0) {
          this.$toast({
            type: 'success',
            message: '注册成功',
            duration: 1500
          })
          this.$store.commit('setToken', token)
          localStorage.setItem('token', token)
          this.$store.commit('setAbility', ability)
          localStorage.setItem('ability', ability)
          setTimeout(() => {
            this.$router.push(`/home?id=${this.$store.state.id}`)
          }, 1500)
        } else this.$toast(msg)
      })
    },
    changeShowpass () {
      const showPass = this.showPass
      this.showPass = !showPass
    },
    changeAgree () {
      const isAgree = this.isAgree
      this.isAgree = !isAgree
    },
    getCode () {
      if (!/^1[3-9]\d{9}$/.test(this.phone)) return this.$toast('手机号码格式不正确')
      if (this.timer) return
      if (!this.canSend) return
      this.canSend = false
      this.$axios.post(getCode, { phone: this.phone, type: 1 }).then(({ code, msg }) => {
        this.canSend = true
        if (code === 0) {
          let time = this.total
          this.time = this.total
          this.timer = setInterval(() => {
            time--
            if (time <= 0) {
              clearInterval(this.timer)
              this.timer = null
            }
            this.time = time
          }, 1000)
        } else this.$toast(msg)
      })
    },
    getQueryString (name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      const r = location.search.substr(1).match(reg)
      if (r !== null) {
        return decodeURIComponent(r[2])
      };
      return null
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      const obj = JSON.parse(localStorage.getItem('registerInfo'))
      if (obj) {
        vm.username = obj.username
        vm.card = obj.identityCard
        vm.phone = obj.phone
        vm.code = obj.code
        vm.password = obj.pwd
        vm.showPass = obj.showPass
        vm.isAgree = obj.isAgree
        vm.canSend = true
        localStorage.removeItem('registerInfo')
      } else {
        vm.username = ''
        vm.card = ''
        vm.phone = ''
        vm.code = ''
        vm.password = ''
        vm.showPass = false
        vm.isAgree = false
        vm.canClick = true
        vm.canSend = true
      }
      const search = location.search
      if (search) {
        vm.canClick = false
        const BizToken = vm.getQueryString('BizToken')
        if (BizToken) vm.getAuthRes(BizToken)
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.timer)
    this.timer = null
    this.time = 60
    next()
  }
}
</script>
<style  lang='less'>
@import '../../assets/less/flex.less';
.van-cell{
  padding: 0;
  font-size: .px2rem(32px)[@rem];
}
.van-field__control{
  color: #101112;
}
.van-button{
  height: .px2rem(108px)[@rem];
  border-radius: .px2rem(20px)[@rem];
  margin-top: .px2rem(194px)[@rem];
}
.van-button--disabled{
  opacity: 1;
}
.van-button__text{
  font-size: .px2rem(36px)[@rem];
}
</style>
<style lang='less' scoped>
@import '../../assets/less/flex.less';
@import '../../assets/less/common.less';
.register-wrapper{
  padding-top: .px2rem(56px)[@rem];
}
.agree-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .px2rem(32px)[@rem];
  margin-bottom: .px2rem(32px)[@rem];
}
.agree-crl{
  width: .px2rem(32px)[@rem];
  height: .px2rem(32px)[@rem];
  border-radius: 50%;
  border: 2px solid #C1C3C7;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: .px2rem(16px)[@rem];
}
.agree-txt{
  font-size: .px2rem(28px)[@rem];
  color: #101112;
}
.color-blue{
  color: #5460FE;
}
.bg-blue{
  background: #5460FE;
  border-color: #5460FE;
}
.form-item-photo{
  padding-bottom: .px2rem(32px)[@rem];
  margin-bottom: .px2rem(56px)[@rem];
}
.photo-txt{
  font-size: .px2rem(32px)[@rem];
  color: #C1C3C7;
  margin-bottom:.px2rem(60px)[@rem]
}
.take-photo{
  width: .px2rem(120px)[@rem];
  height: .px2rem(120px)[@rem];
  background: transparent;
  border: 1px dashed #979797;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trans-border{
  border-color: transparent;
}
.input-file{
  display: none;
}
</style>
