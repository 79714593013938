var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper register-wrapper"},[_c('van-form',{attrs:{"show-error-message":false,"validate-first":""},on:{"submit":_vm.onSubmit,"failed":_vm.onFail}},[_c('div',{staticClass:"form-item hack-border-bottom"},[_c('div',{staticClass:"flex-grow"},[_c('van-field',{attrs:{"placeholder":"请输入姓名","name":"username","autocomplete":"off","maxlength":"10"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)]),_c('div',{staticClass:"form-item hack-border-bottom"},[_c('div',{staticClass:"flex-grow"},[_c('van-field',{attrs:{"placeholder":"请输入身份证号码","name":"identityCard","autocomplete":"off","maxlength":"18","error":false,"rules":[{
            message: '身份证号码不合法',
            pattern: /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
          }]},model:{value:(_vm.card),callback:function ($$v) {_vm.card=$$v},expression:"card"}})],1)]),_c('div',{staticClass:"form-item hack-border-bottom"},[_c('div',{staticClass:"flex-grow"},[_c('van-field',{attrs:{"placeholder":"请输入手机号码","name":"phone","type":"digit","maxlength":"11","autocomplete":"off","error":false,"rules":[{
            message: '手机号码格式不正确',
            pattern: /^1[3-9]\d{9}$/
          }]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)]),_c('div',{staticClass:"form-item hack-border-bottom"},[_c('div',{staticClass:"flex-grow code"},[_c('van-field',{attrs:{"placeholder":"请输入短信验证码","name":"code","type":"digit","autocomplete":"off","maxlength":"6"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c('span',{staticClass:"send-code",on:{"click":_vm.getCode}},[_vm._v(_vm._s(_vm.timer ? _vm.time + 's' : '发送验证码'))])]),_c('div',{staticClass:"form-item hack-border-bottom"},[_c('div',{staticClass:"flex-grow"},[_c('van-field',{attrs:{"placeholder":"设置登录密码","name":"pwd","type":_vm.showPass ? 'text' : 'password',"maxlength":"16","error":false,"rules":[{
            message: '密码格式不正确',
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
          }]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('img',{staticClass:"show-pass",attrs:{"src":_vm.showPass ? _vm.imgs[1] : _vm.imgs[0],"alt":""},on:{"click":_vm.changeShowpass}})]),_c('p',{staticClass:"forget-password rule"},[_vm._v("6-16位密码，同时包含字母和数字，不区分大小写")]),_c('van-button',{style:(_vm.isDisable ? {background: '#8F92BB', 'border-color': '#8F92BB'} : _vm.canClick ? '' : {background: '#e7e7e7', 'border-color': '#e7e7e7'}),attrs:{"disabled":_vm.isDisable,"color":'#5460FE',"block":"","native-type":"submit"}},[_vm._v(_vm._s(_vm.canClick ? '确定' : '认证中...'))])],1),_c('div',{staticClass:"agree-wrapper"},[_c('p',{class:{'agree-crl': true, 'bg-blue': _vm.isAgree},on:{"click":_vm.changeAgree}},[_c('van-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAgree),expression:"isAgree"}],attrs:{"name":"success","color":"#fff","size":"14px"}})],1),_c('p',{staticClass:"agree-txt"},[_vm._v("我已成功并阅读"),_c('a',{staticClass:"color-blue",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.goPrivacy}},[_vm._v("《用户服务协议》")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }